<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Track List ({{ pageArrayStock.length }})</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Create Track</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%">
              <input type="text" class="form-control" placeholder="#" disabled readonly>
            </th>
            <th style="width: 20%"><input v-model="filterTrackname" type="text" class="form-control"
                placeholder="Track Name"></th>
            <th style="width: 20%"><input v-model="filterTrackbet" type="text" class="form-control"
                placeholder="Track Bet"></th>
            <th style="width: 20%"><input v-model="filterTracklap" type="text" class="form-control"
                placeholder="Track Lap"></th>
            <th style="width: 20%"><input v-model="filterTracklimit" type="text" class="form-control"
                placeholder="Track Limit"></th>
            <th style="width: 15%">
              <input type="text" class="form-control" placeholder="Options" disabled readonly>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.trackName }}</a></td>
            <td><a>{{ item.trackBet }}</a></td>
            <td><a>{{ item.trackLap }}</a></td>
            <td><a>{{ item.trackLimit }}</a></td>
            <td class="d-flex justify-content-around">
              <button class="tb-button bg-primary" @click="tableInteractive(item, index, 'edit')">
                <span class="text-white">Edit</span>
              </button>
              <button class="tb-button bg-danger"
                @click="$bvModal.show('bv-modal-tracks'), tableInteractive(item, index, 'delete')">
                <span class="text-white">Delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered>
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create track</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Track Name</label>
          <input v-model="addObject.trackName" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Enum</label>
          <input v-model.number="addObject.trackEnum" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Lap</label>
          <input v-model.number="addObject.trackLap" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Limit</label>
          <input v-model.number="addObject.trackLimit" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Bet</label>
          <input v-model.number="addObject.trackBet" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Type (Only 1)</label>
          <input v-model.number="addObject.trackType" type="text" class="form-control" disabled>
        </div>
        <div class="col-12 mt-1">
          <label>Track Length</label>
          <input v-model.number="addObject.trackLength" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Fuel</label>
          <input v-model.number="addObject.trackFuel" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Image</label>
          <input id="createTrackImage" type="file" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Description</label>
          <textarea v-model="addObject.trackDescription" type="text" class="form-control" cols="30"
            style="resize: none;" />
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1"
            @click="saveData('create')">Create & Close</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered>
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Update track</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Track Name</label>
          <input v-model="editObject.trackName" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Enum</label>
          <input v-model.number="editObject.trackEnum" type="number" disabled class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Lap</label>
          <input v-model.number="editObject.trackLap" type="number" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Limit</label>
          <input v-model.number="editObject.trackLimit" type="number" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Bet</label>
          <input v-model.number="editObject.trackBet" type="number" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Track Type</label>
          <input v-model.number="editObject.trackType" type="number" class="form-control" disabled>
        </div>
        <div class="col-12 mt-1">
          <label>Track Length</label>
          <input v-model.number="editObject.trackLength" type="number" class="form-control" disabled>
        </div>
        <div class="col-12 mt-1">
          <label>Track Fuel</label>
          <input v-model.number="editObject.trackFuel" type="number" class="form-control" disabled>
        </div>
        <!-- <div class="col-12">
          <label>Track Image</label>
          <input id="updateTrackImage" type="file" class="form-control">
        </div> -->
        <div class="col-12 mt-1">
          <label>Track Description</label>
          <textarea v-model="editObject.trackDescription" type="text" class="form-control" cols="30"
            style="resize: none;" />
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1"
            @click="saveData('update')">Update & Close</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal centered id="bv-modal-tracks" hide-header hide-footer>
      <h3 class="text-center mt-2">
        {{ editObject.trackName }}
      </h3>
      <div class="d-block text-center mt-2">
        Are you sure you want to delete this Tracks ?
      </div>
      <div class="col-12 d-flex">
        <b-button class="mt-3 btn-danger mx-1" block @click="$bvModal.hide('bv-modal-tracks'), saveData('delete')">
          Delete
        </b-button>
        <b-button class="mt-3 mx-1" block @click="$bvModal.hide('bv-modal-tracks')">Cancel
        </b-button>
      </div>
    </b-modal>

  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard, BButton, BModal,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],

      addModal: false,
      addObject: {
        trackName: '',
        trackEnum: '',
        trackLap: '',
        trackLimit: '',
        trackBet: '',
        trackType: 1,
        trackLength: 1,
        trackFuel: 1,
        trackDescription: '',
      },

      editModal: false,
      editObject: {
        trackId: '',
        trackName: '',
        trackEnum: '',
        trackLap: '',
        trackLimit: '',
        trackBet: '',
        trackType: '',
        trackLength: '',
        trackFuel: '',
        trackDescription: '',
      },

      filterTrackname: '',
      filterTrackbet: '',
      filterTracklap: '',
      filterTracklimit: '',
    }
  },
  watch: {
    filterTrackname: {
      handler() {
        this.filterNow()
      },
    },
    filterTrackbet: {
      handler() {
        this.filterNow()
      },
    },
    filterTracklimit: {
      handler() {
        this.filterNow()
      },
    },
    filterTracklap: {
      handler() {
        this.filterNow()
      },
    },
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {
      // Clear Variables..
      this.addModal = false
      this.editModal = false
      this.addObject = {
        trackName: '',
        trackDescription: '',
        trackEnum: '',
        trackLap: '',
        trackLimit: '',
        trackBet: '',
        trackType: 1,
        trackLength: 1,
        trackFuel: 1,
      }

      this.filterTrackname = ''
      this.filterTrackbet = ''
      this.filterTracklap = ''
      this.filterTracklimit = ''

      // Get List
      await this.$http.get('/features/tracks/list')
        .then(response => {
          // eslint-disable-next-line no-multi-assign
          this.pageArrayStock = this.pageArray = response.data
          // eslint-disable-next-line no-alert
        }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async tableInteractive(item, index, type) {
      if (type === 'edit') {
        this.editObject = structuredClone(item)
        this.editModal = true
      }
      if (type === 'delete') {
        this.editObject = structuredClone(item)
      }
    },
    async saveData(type) {
      if (type === 'create') {

        const formData = new FormData()
        const trackImage = document.getElementById('createTrackImage').files[0]
        formData.append('trackName', this.addObject.trackName)
        formData.append('trackEnum', this.addObject.trackEnum)
        formData.append('trackLap', this.addObject.trackLap)
        formData.append('trackLimit', this.addObject.trackLimit)
        formData.append('trackBet', this.addObject.trackBet)
        formData.append('trackType', 1)
        formData.append('trackLength', 1)
        formData.append('trackFuel', 1)
        formData.append('trackImage', trackImage)
        formData.append('trackDescription', this.addObject.trackDescription)

        await this.$http.post('/features/tracks/create', formData, { headers: { 'Content-Type': `multipart/form-data` } })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            // eslint-disable-next-line default-case
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'update') {
        const formData = new FormData()
        // const trackImage2 = document.getElementById('updateTrackImage').files[0]
        formData.append('trackName', this.editObject.trackName)
        formData.append('trackId', this.editObject.trackId)
        formData.append('trackEnum', this.editObject.trackEnum)
        formData.append('trackLap', this.editObject.trackLap)
        formData.append('trackLimit', this.editObject.trackLimit)
        formData.append('trackBet', this.editObject.trackBet)
        formData.append('trackType', this.editObject.trackType)
        formData.append('trackLength', this.editObject.trackLength)
        formData.append('trackFuel', this.editObject.trackFuel)
        // formData.append('trackImage', trackImage2)
        formData.append('trackDescription', this.editObject.trackDescription)

        // for (var pair of formData) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }

        await this.$http({
          method: 'POST',
          url: '/features/tracks/update',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            // eslint-disable-next-line default-case
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'delete') {
        await this.$http.post('/features/tracks/delete', { trackId: this.editObject.trackId })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            // eslint-disable-next-line default-case
            switch (error.response.data) {
              case 'CannotDeleteYourself': errorMessage = 'Cannot Delete Yourself'; break
              case 'PermissionError': errorMessage = 'Permission Error!'; break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.trackName.toLocaleLowerCase().includes(this.filterTrackname.toLocaleLowerCase()) && x.trackLap.toString().toLocaleLowerCase().includes(this.filterTracklap.toLocaleLowerCase()) && x.trackBet.toString().toLocaleLowerCase().includes(this.filterTrackbet.toLocaleLowerCase()) && x.trackLimit.toString().toLocaleLowerCase().includes(this.filterTracklimit.toLocaleLowerCase()))
    },
  },
}

</script>
